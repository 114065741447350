import React from 'react'
import { Icon } from 'semantic-ui-react'
import file from './AlexanderKarren_Resume_2020.pdf'
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';

const Resume = () => {
    return (
        <div className="resume">
            <h2>Resume</h2>
            <a href="AlexanderKarren_Resume_2020.pdf" className="download-box">
                <Icon name="download" />
                <div>AlexanderKarren_Resume_2020.pdf</div>
            </a>
            <a href="AlexanderKarren_Resume_2020.pdf"><Document
                file={file}
            >
                <Page pageNumber={1} />
            </Document></a>
        </div>
    )
}

export default Resume
