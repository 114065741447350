import React, { useState, useEffect } from 'react'
import { Sticky } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

const Header = () => {
    const [tintVisible, setTintVisibility] = useState(false);
    const [infoVisible, setInfoVisibility] = useState(false);
    const [initialHeight] = useState(window.innerHeight);
    const { pathname } = useLocation();
    const { push } = useHistory();

    useEffect(() => {
        setTintVisibility(true);
        setTimeout(() => setInfoVisibility(true), 500)
    }, [])

    const scrollDown = path => {
        if (path) push(path);
        else push("/");
        setTimeout(() => {
            if (window.innerWidth >= 500) {
                window.scrollTo({
                    top: window.innerHeight - 70,
                    behavior: 'smooth',
                });
            }
            else {
                window.scrollTo({
                    top: window.innerHeight - 200,
                    behavior: 'smooth',
                });
            }

        }, 1)
    }

    return (
        <header style={{height:`${initialHeight}px`}}>
            <div className={tintVisible ? "bg-tint" : "bg-tint bg-tint-invisible"}>
                <Sticky>
                    <nav className={(infoVisible) ? "sticky" : "sticky invisible"}>
                        <div className={pathname.includes("/skills") ? "active-link" : ""}
                            onClick={() => scrollDown("/skills")}>
                            Skills
                        </div>
                        <div className={pathname.includes("/projects") ? "active-link" : ""}
                            onClick={() => scrollDown()}>
                            Projects
                        </div>
                        <div className={pathname.includes("/about") ? "active-link" : ""}
                            onClick={() => scrollDown("/about")}>
                            About Me
                        </div>
                        <div className={pathname.includes("/resume") ? "active-link" : ""}
                            onClick={() => scrollDown("/resume")}>
                            Resume
                        </div>
                    </nav>
                </Sticky>
                <div className={infoVisible ? "header-content" : "header-content header-content-invisible"}>
                    <div className="header-text">
                        <h1>Alexander Karren</h1>
                        <p>Full-stack software engineer</p>
                    </div>
                    <div className="header-social">
                        {/* <a href="https://www.twitter.com"><Icon name="twitter" /></a> */}
                        <a href="https://www.linkedin.com/in/alexander-karren-71b1991a3/"><Icon name="linkedin" /></a>
                        <a href="https://github.com/AlexanderKarren"><Icon name="github" /></a>
                        <a href="mailto:alex@karren.com"><Icon name="mail" /></a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
