import React from 'react'
import { Icon } from 'semantic-ui-react'

const Project = ({ project }) => {
    return (
        <div className="project">
            <div className="project-image">
                <a href={project.link} className={project.screenshot_m ? "mobile" : ""}>
                    <img src={project.screenshot} alt={project.title} />
                    {project.screenshot_m && <img src={project.screenshot_m} alt={`${project.title} mobile`} />}
                </a>
            </div>
            <div className="project-info">
                <a className="project-link" href={project.link}><h3>{project.title}</h3><Icon name="linkify" /></a>
                <p>{project.description}</p>
                {project.bullets && <ul>
                    {project.bullets.map(bullet => <li>{bullet}</li>)}   
                </ul>}
                <div className="tags">
                    {project.tags.map((tag, index) => <p key={`tag-${index}`} className="tag">{tag}</p>)}
                </div>
                {project.github && <div className="github-links">
                    {project.github.map(repo => <p className="github">
                        <a className="primary-link" href={repo.link}>
                            Github Repo ({repo.type})
                        </a>
                    </p>)}
                </div>}
            </div>
        </div>
    )
}

export default Project
