export const projects = [
    {
        id: 1,
        title: "The Cocktail Compendium",
        description: "Web application that allows users to navigate and contribute to a large database of cocktail recipes, ingredients, and glassware. (WIP)",
        bullets: [
            "Solo full-stack project",
            "Front-end written in JS / react, back-end written in node.js / express",
            "Styled using Semantic-UI and Sass",
            "Users can contribute to both the cocktails and ingredients tables in the database",
            "Users have sophisticated account hub and access to account settings"
        ],
        link: "https://thecocktailcompendium.app/",
        github: [{
            link: "https://github.com/AlexanderKarren/cocktail-compendium-fe",
            type: "Front-End"
        }],
        screenshot: "screenshots/cocktail.png",
        screenshot_m: "screenshots/cocktail-m.png",
        tags: ["SCSS", "JS", "React", "Redux", "Express", "Node.js"]
    },
    {
        id: 2,
        title: "Conway's Game of Life",
        description: "Cellular simulation algorithm written in Javascript. Algorithm originally invented by John Conway.",
        bullets: [
            "Solo project",
            "Styled using Material-UI",
            "Users can run simulation and specify speed, or step through one at a time",
            "Users can randomize cells and select presets"
        ],
        link: "https://conways-game-of-life-zeta.vercel.app/",
        github: [{
            link: "https://github.com/AlexanderKarren/conways-game-of-life",
            type: "Front-End"
        }],
        screenshot: "screenshots/conway.png",
        tags: ["CSS", "JS", "React", "Computer Science"]
    },
    {
        id: 3,
        title: "Expat Journal - Capture",
        description: "Capture is a proof-of-concept Instagram competitor for those with a strong passion for traveling the world.",
        bullets: [
            "Built landing page with design provided by UX teammate",
            "Built “about us” team page with design provided by UX teammate",
            "Designed and built additional contact page, mimicking the style of the design schema"
        ],
        link: "https://bw-expatjournal.github.io/MarketingPage/",
        github: [{
            link: "https://github.com/bw-exPatJournal/MarketingPage",
            type: "Front-End"
        }],
        screenshot: "screenshots/capture.png",
        screenshot_m: "screenshots/capture-m.png",
        tags: ["HTML", "CSS"]
    },
    {
        id: 4,
        title: "Todo App 2",
        description: "React-based to-do app built using reducers to manage state.",
        bullets: [
            "Solo project",
            "Designed UX on Photoshop and used CSS for styling",
            "Local storage for preserving tasks",
            "Tasks can be filtered by user-specified tags",
            "Moment library is utilized for overdue tasks"
        ],
        link: "https://todo-app-2.now.sh/",
        github: [{
            link: "https://github.com/AlexanderKarren/reducer-todo",
            type: "Front-End"
        }],
        screenshot: "screenshots/todoapp.png",
        tags: ["CSS", "JS", "React"]
    },
    {
        id: 5,
        title: "Med Cabinet - Dr. Mary Jane",
        description: "React app for those with chronic illnesses to get medical marijuana strain recommendations based on their symptoms and preferences. Created with a team of data scientists, a back-end developer and one other front-end developer.",
        bullets: [
            "Built and designed strain list page",
            "Styled pages using Material-UI library for React",
            "Wrote front-end cabinet functionality (users can favorite strains and view their favorites in one location)",
            "On the front-end, designed and wrote necessary API calls for login and signup pages"
        ],
        link: "https://dr-mary-jane.now.sh/",
        github: [{
            link: "https://github.com/Build-Week-mdd-cabinet1/front-end",
            type: "Front-End"
        }],
        screenshot: "screenshots/cabinet.png",
        tags: ["CSS", "JS", "React"]
    },
]