import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="footer-social">
                {/* <a href="https://www.twitter.com" className="primary-link">Twitter</a> */}
                <a href="https://www.linkedin.com/in/alexander-karren" className="primary-link">LinkedIn</a>
                <a href="https://github.com/AlexanderKarren" className="primary-link">Github</a>
                <a href="mailto:alex@karren.com" className="primary-link">Email</a>
            </div>
            <div className="copyright">&copy; 2020 Alexander Karren</div>
        </footer>
    )
}

export default Footer
