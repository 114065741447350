import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from './components/Header'
import Skills from './components/Skills'
import AboutMe from './components/AboutMe'
import Projects from './components/Projects'
import Footer from './components/Footer'
import Resume from './components/Resume'

const App = () => {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path="/skills"><Skills /></Route>
        <Route path="/about"><AboutMe /></Route>
        <Route path="/projects"><Projects /></Route>
        <Route path="/resume"><Resume /></Route>
        <Redirect from="/" to="/projects" />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
