import React from 'react'
import { projects } from '../data'
import Project from './Project'

const Projects = () => {
    return (
        <div className="projects">
            <h2>Projects</h2>
            {projects.map(project => <Project key={project.id} project={project}/>)}
        </div>
    )
}

export default Projects
