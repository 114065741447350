import React from 'react'
import avi from '../me.jpg'

const AboutMe = () => {
    return (
        <div className="about-me">
            <h2>About Me</h2>
            <div className="about-me-container">
                <div className="about-me-div">
                    <img src={avi} alt="Alexander Karren"/>
                    <div className="about-me-info">
                        <p>Hey there! I'm Alexander Karren. Born and raised in the beautiful state of Utah, I've been studying computer science since graduating high school, and nowadays I specialize in web development.</p>
                        <p>I've always loved hiking and camping with family and friends, so every chance I get I'll sneak one of the nature photos I've taken into my projects.</p>
                        <p>If you need to get in touch with me for a project, you can reach me at <a className="primary-link" href="mailto:alex@karren.com">alex@karren.com.</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMe
