import React from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

const Skills = () => {
    return (
        <div className="skills">
            <h2>Skills</h2>
            <div className="skills-container">
                <div className="skills-icons">
                    <ReactSVG src="/skills/HTML5.svg" />
                    <ReactSVG src="/skills/CSS.svg" />
                    <ReactSVG src="/skills/React.svg" />
                    <ReactSVG src="/skills/nodejs.svg" />
                </div>
                <p className="skills-text">I studied full-stack web development and computer science at <a className="primary-link" href="https://lambdaschool.com/">Lambda School</a> from January 2020 to September 2020, and I learned and mastered the fundamentals of HTML, CSS, Javascript, a popular Javascript framework called React, Node.js, and a framework for building REST APIs called Express.</p>
                <p className="skills-text">I also have experience using Python to learn computer science fundamentals and solve coding challenges.</p>
                <p className="skills-text"><Link className="primary-link" to="/">Check out some of the cool stuff I've made!</Link></p>
                <p className="skills-text">Other skills:</p>
                <p className="skills-text indented">
                    <ul>
                        <li>Problem solving</li>
                        <li>Working with a team</li>
                        <li>Designing user flow</li>
                        <li>Styling frameworks and libraries</li>
                        <ul>
                            <li>Less</li>
                            <li>Sass</li>
                            <li>Material-UI and Semantic-UI</li>
                            <li>Styled Components</li>
                        </ul>
                    </ul>
                </p>
            </div>
        </div>
    )
}

export default Skills
